<template>
  <div id="app">
    <header>
      <nav>
        <ul>
          <li><router-link to="/">Home</router-link></li>
        </ul>
      </nav>
    </header>
    <main>
      <router-view/>
    </main>
  </div>
</template>

<style>
/* Basic styling */
body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}
header {
  background: #333;
  color: white;
  padding: 10px 20px;
}
nav ul {
  list-style-type: none;
  padding: 0;
}
nav ul li {
  display: inline;
  margin-right: 10px;
}
nav ul li a {
  color: white;
  text-decoration: none;
}
</style>
