<template>
  <div class="home-page">
    <h1 class="title">Credit Card Management System</h1>
    <div class="upload-container">
      <div class="upload-area" @dragover.prevent="dragOver" @dragleave.prevent="dragLeave" @drop.prevent="onFileDrop('statements', $event)">
        <label for="statements-upload" class="upload-label">
          <p v-if="statementsFileName">{{ statementsFileName }}</p>
          <p v-else>Upload Credit Card Statement</p>
        </label>
        <input type="file" id="statements-upload" ref="statementsUpload" @change="handleStatementsUpload" style="display: none;" accept=".pdf"/>
      </div>
      <div class="upload-area" @dragover.prevent="dragOver" @dragleave.prevent="dragLeave" @drop.prevent="onFileDrop('receipts', $event)">
        <label for="receipts-upload" class="upload-label">
          <p v-if="receiptsFileNames.length">{{ receiptsFileNames.length }} receipt(s) uploaded</p>
          <p v-else>Upload Receipts</p>
        </label>
        <input type="file" id="receipts-upload" ref="receiptsUpload" @change="handleReceiptsUpload" style="display: none;" multiple/>
      </div>
      <!-- Button added after the upload areas -->
      <button @click="processTransactions" class="upload-btn">Submit All</button>
    </div>

    <div v-if="processed">
      <div class="upload-container" style="margin-top: -2rem;">
        <div class="upload-area">
          <h1 class="sub-title">Good to Pay: {{ gtp }} / {{ totalGTP }}</h1>
        </div>
        <div class="upload-area">
          <button @click="exportToExcel()" class="upload-btn">Generate Recon</button>
        </div>
      </div>

      <table id="data-table">
      <thead>
        <tr>
          <th>Line #</th>
          <th>Date</th>
          <th>Reference</th>
          <th>Statement Vendor</th>
          <th>Receipt Vendor</th>
          <th>Category</th>
          <th>Statement Total TTD</th>
          <th>Statement Total USD (If Applicable)</th>
          <th>Receipt Total</th>
          <th>Good to pay</th>
          <th>Remove</th>
        </tr>
      </thead>
      <tbody>
        <tr :class="GTPRow(item.found)" v-for="(item, index) in matchedStatement" :key="index">
          <td>{{ index + 1 }}</td> <!-- Assuming you want to keep this as a static index or you can make it editable too -->
          <td><input type="text" v-model="item.transaction_date" class="edit-input"></td>
          <td><input type="text" v-model="item.reference" class="edit-input"></td>
          <td><input type="text" v-model="item.description" class="edit-input"></td>
          <td><input type="text" v-model="item.vendor" class="edit-input"></td>
          <td>
            <select v-model="item.category" class="select-style">
                <option value="subscriptions">Subscriptions</option>
                <option value="staff_meals">Saff Meals</option>
                <option value="bank_fees">Bank Fees</option>
                <option value="director_costs">Director Costs</option>
                <option value="family_costs">Family Costs</option>
                <option value="vouchers">Vouchers</option>
                <option value="1:1">1:1</option>
                <option value="external_meeting">External Meeting</option>
                <option value="other">Other</option>
              </select>
          </td>
          <td><input type="text" v-model="item.total" class="edit-input"></td>
          <td><input type="text" v-model="item.usd_value" class="edit-input" @input="item.usd_value = USDFormat($event.target.value)"></td>
          <td><input type="text" v-model="item.receipt_total" class="edit-input" @input="item.receipt_total = USDFormat($event.target.value)"></td>
          <td :class="GTPClass(item.found)">
              <select v-model="item.found" @change="updateFound(item)" class="select-style">
                  <option value="true">Yes</option>
                  <option value="false">No</option>
              </select>
          </td>
          <td><button @click="deleteRow(index)" class="icon-button"><span class="material-icons">delete</span></button></td>
      </tr>
      </tbody>
    </table>
    <div class="upload-container">
      <div class="upload-area">
        <button @click="addRow" class="upload-btn">Add New Row</button>
      </div>
    </div>
    <br/>
    </div>
  </div>
</template>

<script>

import Swal from 'sweetalert2'
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';

// let backend_url = "http://localhost:3000"
let backend_url = "https://ccrecon.rampslogistics.com"

export default {
  name: 'HomePage',
  data() {
    return {
      statementsFileName: '',
      receiptsFileNames: '',
      processed: false,
      receiptsFiles: [],
      statementsFile: [],
      matchedStatement: [],
      receipts: [],
      transactions: [],
      failed: false,
    };
  },

  computed:{
    gtp(){
      var count = 0
      this.matchedStatement.forEach(item => {
        if(item.found == true){
          count = count + 1
        }
      })
      return count
    },
    totalGTP(){
      return this.matchedStatement.length
    }
  },
  
  methods: {
    async exportToExcel() {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('CC-Recon', {
        properties: { defaultColWidth: 20 }
      });

      // Define custom column widths
      worksheet.columns = [
        { header: 'Line #', key: 'line_number', width: 6 },
        { header: 'Date', key: 'date', width: 12 },
        { header: 'Reference', key: 'reference', width: 20 },
        { header: 'Statement Vendor', key: 'statement_vendor', width: 45 },
        { header: 'Category', key: 'category', width: 20 },
        { header: 'Statement Total TTD', key: 'statement_total_ttd', width: 20 },
        { header: 'Statement Total USD (If Applicable)', key: 'statement_total_usd', width: 20 },
        { header: 'Receipt Total', key: 'receipt_total', width: 15 },
        { header: 'Good to Pay', key: 'good_to_pay', width: 15 }
      ];

      const table = document.getElementById('data-table');
      const rows = table.querySelectorAll('tbody tr');

      // Add data rows
      rows.forEach(row => {
        const cells = Array.from(row.querySelectorAll('td'));
        const rowData = cells.map((cell, index) => {
          if (index === 4 || index === 10) { // Skip the 'Receipt Vendor' column
            return null;
          }
          const input = cell.querySelector('input');
          const select = cell.querySelector('select');
          let value = "";
          if (input) {
            value = input.value;
          } else if (select) {
            value = select.value;
          } else {
            value = cell.innerText;
          }
          
          // Transformations based on column index
          if (index === 5) { // Category column
            value = value.replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase());
          }
          if (index === 9) { // Good to Pay column
            value = value === 'true' ? 'Yes' : 'No';
          }
          return value;
        }).filter(v => v !== null); // Remove null values (skipped columns)
        worksheet.addRow(rowData);
      });

      // Styling for the header row
      const headerRow = worksheet.getRow(1);
      headerRow.font = { bold: true, color: { argb: 'FFFFFFFF' } };
      for (let i = 1; i <= 9; i++) {  // Apply styles only from column A to I
        headerRow.getCell(i).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FF008000' }  // Deep green color
        };
      }

      // Set borders for all cells
      worksheet.eachRow({ includeEmpty: true }, function(row) {
        row.eachCell({ includeEmpty: true }, function(cell) {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        });
      });

      const buffer = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), 'CC-Recon.xlsx');
    },

    deleteRow(index) {
        this.matchedStatement.splice(index, 1)
    },

    addRow() {
        const newRow = {
            transaction_date: '',
            reference: '',
            description: '',
            vendor: '',
            category: '',
            total: '',
            usd_value: '',
            receipt_total: '',
            found: 'false'
        };
        this.matchedStatement.push(newRow)
    },

    calculateGTP(found){
      if(found === true){
        return "Yes"
      }else{
        return "No"
      }
    },

    GTPClass(found){
      if(found === true){
        return "yes"
      }else{
        return "no"
      }
    },

    GTPRow(found){
      if(found === true){
        return "lightgreen"
      }else{
        return "lightred"
      }
    },

    updateFound(item) {
      const newFoundValue = item.found === 'true'
      item.found = newFoundValue

    },

    handleStatementsUpload(event) {
      this.statementsFile = event.target.files[0];
      this.statementsFileName = event.target.files[0].name;
    },
    handleReceiptsUpload(event) {
      this.receiptsFiles = Array.from(event.target.files);
      this.receiptsFileNames = this.receiptsFiles.map(file => file.name);
    },
    onFileDrop(type, event) {
      const input = this.$refs[type + 'Upload'];
      input.files = event.dataTransfer.files;
      if (type === 'statements') {
        this.handleStatementsUpload({ target: input });
      } else if (type === 'receipts') {
        this.handleReceiptsUpload({ target: input });
      }
    },
    dragOver(event) {
      event.preventDefault();
    },
    dragLeave(event) {
      event.preventDefault();
    },

    showFailed() {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Loading failed, please try again.',
        allowOutsideClick: false,
      });
      setTimeout(() => {
        Swal.close();
      }, 3000)
    },

    showLoading() {
      Swal.fire({
        title: 'Loading...',
        text: 'Please wait',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      })
    },

    // In your Vue component's methods
    async fetchReceipts() {

      const formData = new FormData();
      for (let file of this.receiptsFiles) {
          formData.append('receipts', file);
      }

      try {
        const response = await fetch(`${backend_url}/api/upload`, {
          method: 'POST',
          body: formData,
        });

        await response.json().then(res=>this.updateReceiptForTable(res))

      } catch (error) {
        console.error('Error uploading files:', error);
      }
    },

    async fetchTransactions() {

      const formData = new FormData()
      formData.append('statement', this.statementsFile)

      try {
        const response = await fetch(`${backend_url}/api/statement`, {
          method: 'POST',
          body: formData,
        });

        await response.json().then(res=>this.updateStatementForTable(res))

      } catch (error) {
        console.error('Error uploading files:', error);
      }
    },

    updateReceiptForTable(data){
      try{
        const extractedData = data.map(item => ({
            merchant: item.MerchantName,
            total: item.Total
        }))
        
        this.receipts.push(extractedData)
      }catch (err){
        this.failed = true
      }

    },

    extractUSDAmount(description) {
      const regex = /\((\d+\.\d+)\s*USD\)/i;  // Case-insensitive regex to extract amount before "USD"
      const match = description.match(regex);
      return match ? parseFloat(match[1]) : null;  // Parse the amount as a float, or return null if no match
    },

    updateStatementForTable(data) {
      try{

        const extractedData = data[0].map(item => {
          const usdAmount = this.extractUSDAmount(item.description)
          return {
            transaction_date: item.transactionDate,
            reference: item.referenceNo,
            description: item.description,
            category: item.category,
            total: item.amount,
            usd_value: usdAmount,
            found: false,
            receipt_total: 0,
            vendor: '',
          };
        });

        this.transactions.push(...extractedData)
      }catch(err){
        this.failed = true
      }
    },

    USDFormat(value){
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      })

      return formatter.format(value)

    },

    normalizeString(str) {
      if (!str) return '';  // Return an empty string if the input is null or undefined
      return str.toLowerCase().replace(/[^a-z0-9\s]/gi, '');
    },
    extractKeyWords(name) {
      return name.split(/\s+/);
    },
    checkAmounts(receiptAmount, transactionAmountStr) {
      const transactionAmount = parseFloat(transactionAmountStr.replace(/[^\d.-]/g, ''));
      return Math.abs(transactionAmount - receiptAmount) < 5;
    },

    processTransactions(){
      this.processed = false
      this.matchedStatement = []
      this.receipts = []
      this.transactions = []
      this.failed = false

      Promise.all([this.showLoading(), this.fetchReceipts(), this.fetchTransactions()])
      // eslint-disable-next-line
      .then(results => {this.matchTransactions()}).catch(error => {console.error("Error fetching data:", error)})
    },

    matchTransactions() {

      if (this.receipts[0]){
        const matches = []
        
        this.receipts[0].forEach(receipt => {
          const normalizedReceiptName = this.normalizeString(receipt.merchant)
          const receiptKeywords = this.extractKeyWords(normalizedReceiptName)
          this.transactions.forEach(transaction => {
            const normalizedTransactionDescription = this.normalizeString(transaction.description)
            const allKeywordsMatch = receiptKeywords.some(keyword => normalizedTransactionDescription.includes(keyword))
            var amount_comparison = 0
            transaction.usd_value ? amount_comparison = transaction.usd_value : amount_comparison = transaction.total
            
            if (allKeywordsMatch && this.checkAmounts(receipt.total, amount_comparison.toString())) {
            matches.push({
                transaction_date: transaction.transaction_date,
                reference: transaction.reference,
                description: transaction.description,
                total: transaction.total,
                vendor: receipt.merchant,
                receipt_total : receipt.total,
                usd_value: transaction.usd_value,
                category: transaction.category,
                found: true 
              })
            }          
          })
        })

        // Second pass: add all transactions, marking unmatched ones
        this.transactions.forEach(transaction => {
          const alreadyMatched = matches.some(match => match.reference === transaction.reference)
          if (!alreadyMatched) {
            matches.push({
              transaction_date: transaction.transaction_date,
              reference: transaction.reference,
              description: transaction.description,
              total: transaction.total,
              vendor: null, 
              receipt_total: null,
              category: transaction.category,
              usd_value: transaction.usd_value,
              found: false
            })
          }
        })

        this.matchedStatement = matches
        this.processed = true
        Swal.close()
      }else{
        this.showFailed()
      }
    }
  }
}
</script>

<style scoped>

.no{
  color: darkred;
  font-weight: bold;
}

.yes{
  color: green;
  font-weight: bold;
}

.lightgreen{
  background-color: #a7caa4;
}

.lightred{
  background-color: #cea9a9;
}

/* Reset some default styles */
#data-table {
  border-collapse: collapse;
  width: 100%;
  margin: 20px 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
  box-shadow: 0 0 20px rgba(0,0,0,0.15);
}

#data-table th,
#data-table td {
  padding: 12px 15px;
  text-align: left;
}

#data-table thead {
  background-color: #009879;
  color: #ffffff;
  border-bottom: solid 3px #ffffff;
}

#data-table tbody tr {
  border-bottom: thin solid #dddddd;
}

#data-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

#data-table tbody tr:hover {
  cursor: pointer;
}

/* Responsive text alignment and padding for better mobile viewing */
@media screen and (max-width: 600px) {
  #data-table th, #data-table td {
    text-align: left;
    padding: 8px;
  }
}


.upload-label {
  display: inline-block;
  padding: 10px;
  background-color: #f0f0f0;
  border: 2px dashed #ccc;
  cursor: pointer;
}

.upload-label:hover {
  background-color: #e9e9e9;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin: 5px 0;
  padding: 5px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
}

.edit-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; /* Makes sure padding doesn't affect the width */
    background-color: #dce8ed4d;
    color: #333;
    font-size: 16px;
}

.sub-title {
  font-size: 26px;
  color: #4A90E2; /* A nice blue */
  text-align: center;
}

.title {
  font-size: 32px;
  color: #4A90E2; /* A nice blue */
  text-align: center;
  margin: 20px 0;
}
.upload-container {
  display: flex;
  justify-content: center; /* This spreads out the children evenly */
  align-items: center; /* Aligns children vertically in the middle */
}

.upload-area {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10px; /* Adds some space around each upload area */
  padding: 20px;
  width: 300px; /* Or any width according to your design */
}

/* Style for the button */
.upload-btn {
  padding: 10px 20px;
  background-color: #4c7aaf; /* Green background */
  color: white; /* White text color */
  border: none;
  width: 200px;
  font-size: 1rem;
  height: 4.5rem;
  cursor: pointer;
}

.upload-label {
  margin: 20px;
  padding: 10px 20px;
  border: 2px dashed #4A90E2;
  cursor: pointer;
  color: #4A90E2;
  text-align: center;
  width: 300px; /* Fixed width for better control */
}
.upload-label:hover {
  background-color: #f4f4f9; /* Slight background color on hover for better UX */
}

/* Styles for the <select> element */
  .select-style {
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #dce8ed4d;
  font-size: 16px;
  color: #333;
  outline: none; /* Removes the default focus outline */
  cursor: pointer; /* Changes cursor to pointer to indicate it's clickable */
}

/* Change appearance on hover */
.select-style:hover {
  border-color: #888;
}

/* Styles when the select is focused (clicked on) */
.select-style:focus {
  border-color: #0056b3;
  box-shadow: 0 0 8px rgba(0, 86, 179, 0.5);
}

.icon-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.icon-button:hover {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
}


</style>